<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <CCol>
          <h1 class="mr-sm-4 header">LINE MESSAGE MANAGEMENT</h1>
        </CCol>
      </CRow>
      <div class="bg-white-border px-4 px-sm-5 mt-3 py-4">
        <b-row>
          <b-col>
            <div v-for="(item, i) in items" :key="i">
              <b-button v-b-toggle="'collapse-'+i" variant="primary" class="btn-collapse">
                <font-awesome-icon icon="chevron-right" class=" f-18 mr-2" :class="[ item.subList.length != 0 ? 'text-secondary' : 'text-white' ]"/>
                <font-awesome-icon icon="chevron-down" class=" f-18 mr-2" :class="[ item.subList.length != 0 ? 'text-secondary' : 'text-white' ]"/>
                <span class="template-color-green f-18" @click="checkIsImageList(item.isImageList)">
                  <router-link :to="'/linemessage/'+item.intentId">{{item.name}}</router-link>
                  <!-- Level 1 -->
                </span>
              </b-button>
              <b-collapse :id="'collapse-'+i" class="mt-2">
                <b-card class="card-box mb-0">
                  <div v-for="(item2, j) in item.subList" :key="j">
                    <b-button
                      v-b-toggle="'collapse-' + item2.intentId +'-inner'"
                      class="btn-collapse"
                    >
                      <font-awesome-icon
                        icon="chevron-right"
                        class="f-18 mr-2"
                        :class="[ item2.subList.length != 0 ? 'text-secondary' : 'text-white' ]"
                      />
                      <font-awesome-icon
                        icon="chevron-down"
                        class="f-18 mr-2"
                        :class="[ item2.subList.length != 0 ? 'text-secondary' : 'text-white' ]"
                      />
                      <span
                        class="template-color-green f-18"
                        @click="checkIsImageList(item2.isImageList)"
                      >
                        <router-link :to="'/linemessage/'+item2.intentId">{{item2.name}}</router-link>
                        <!-- Level 2 -->
                      </span>
                    </b-button>
                    <b-collapse
                      :id="'collapse-'+item2.intentId+'-inner'"
                      class="mt-2"
                      v-if="item2.subList.length != 0"
                    >
                      <b-card class="card-box mb-0">
                        <div v-for="(item3, k) in item2.subList" :key="k">
                          <b-button
                            v-b-toggle="'collapse2-' + item3.intentId +'-inner'"
                            class="btn-collapse"
                          >
                            <font-awesome-icon
                              icon="chevron-right"
                              class="f-18 mr-2"
                              :class="[ item3.subList.length != 0 ? 'text-secondary' : 'text-white' ]"
                            />
                            <font-awesome-icon
                              icon="chevron-down"
                              class="f-18 mr-2"
                              :class="[ item3.subList.length != 0 ? 'text-secondary' : 'text-white' ]"
                            />
                            <span
                              class="template-color-green f-18"
                              @click="checkIsImageList(item3.isImageList)"
                            >
                              <router-link :to="'/linemessage/'+item3.intentId">{{item3.name}}</router-link>
                              <!-- Level 3 -->
                            </span>
                          </b-button>
                          <b-collapse
                            :id="'collapse2-'+item3.intentId+'-inner'"
                            class="mt-2"
                            v-if="item3.subList.length != 0"
                          >
                            <b-card class="card-box mb-0">
                              <div v-for="(item4, l) in item3.subList" :key="l">
                                <b-button
                                  v-b-toggle="'collapse3-' + item4.intentId +'-inner'"
                                  class="btn-collapse"
                                >
                                  <font-awesome-icon
                                    icon="chevron-right"
                                    class="f-18 mr-2"
                                    :class="[ item4.subList.length != 0 ? 'text-secondary' : 'text-white' ]"
                                  />
                                  <font-awesome-icon
                                    icon="chevron-down"
                                    class="f-18 mr-2"
                                    :class="[ item4.subList.length != 0 ? 'text-secondary' : 'text-white' ]"
                                  />
                                  <span
                                    class="template-color-green f-18"
                                    @click="checkIsImageList(item4.isImageList)"
                                  >
                                    <router-link :to="'/linemessage/'+item4.intentId">{{item4.name}}</router-link>
                                    <!-- Level 4 -->
                                  </span>
                                </b-button>
                                <b-collapse
                                  :id="'collapse3-'+item4.intentId+'-inner'"
                                  class="mt-2"
                                  v-if="item4.subList.length != 0"
                                >
                                  <b-card class="card-box mb-2">
                                    <div v-for="(item5, m) in item4.subList" :key="m">
                                      <b-button
                                        v-b-toggle="'collapse4-' + item5.intentId +'-inner'"
                                        class="btn-collapse"
                                      >
                                        <font-awesome-icon
                                          icon="chevron-right"
                                          class="f-18 mr-2"
                                          :class="[ item5.subList.length != 0 ? 'text-secondary' : 'text-white' ]"
                                        />
                                        <font-awesome-icon
                                          icon="chevron-down"
                                          class="f-18 mr-2"
                                          :class="[ item5.subList.length != 0 ? 'text-secondary' : 'text-white' ]"
                                        />
                                        <span
                                          class="template-color-green f-18"
                                          @click="checkIsImageList(item5.isImageList)"
                                        >
                                          <router-link
                                            :to="'/linemessage/'+item5.intentId"
                                          >{{item5.name}}</router-link>
                                          <!-- Level 5 -->
                                        </span>
                                      </b-button>

                                      <b-collapse
                                        :id="'collapse4-'+item5.intentId+'-inner'"
                                        v-if="item4.subList.length != 0"
                                      >
                                        <b-card class="card-box mb-0">
                                          <div
                                            v-for="(item6, n) in item5.subList"
                                            :key="n"
                                            class="btn-collapse my-3"
                                          >
                                            <span
                                              class="template-color-green f-18"
                                              @click="checkIsImageList(item6.isImageList)"
                                            >
                                              <router-link
                                                :to="'/linemessage/'+item6.intentId"
                                              >{{item6.name}}</router-link>
                                              <!-- Level 6 -->
                                            </span>
                                          </div>
                                        </b-card>
                                      </b-collapse>
                                    </div>
                                  </b-card>
                                </b-collapse>
                              </div>
                            </b-card>
                          </b-collapse>
                        </div>
                      </b-card>
                    </b-collapse>
                  </div>
                </b-card>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";

export default {
  name: "linemsgindex",
  data() {
    return {
      items: [],
    };
  },
  created: async function () {
    this.$isLoading = true;
    await this.getData();
    this.$isLoading = false;
  },
  methods: {
    getData: async function () {
      this.isBusy = true;
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/message/listHierarchy`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.items = resData.detail;
      }
    },
    checkIsImageList(data) {
      this.$cookies.set("IsImageList", data, 60 * 60 * 24 * 30);
    },
  },
};
</script>

<style scoped>
.line-msg-box {
  border-bottom: 1px solid;
  border-color: #d8dbe0;
}

.line-msg-link,
.line-msg-link:hover {
  text-decoration: none;
}

.f-18 {
  font-size: 18px;
}

.btn-collapse {
  border-radius: 0;
  background-color: white !important;
  font-size: 17px;
  padding: 0;
  border: 0;
  border-bottom: 1px solid;
  border-color: #d8dbe0;
  width: 100%;
  text-align: left;
}

.collapsed {
  margin-bottom: 16px;
}

.card {
  border: 0;
}

.collapsed > .fa-chevron-down,
:not(.collapsed) > .fa-chevron-right {
  display: none;
}

.card-box > .card-body {
  padding: 0.5rem 1.25rem;
}
</style>